/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */

@import "variables";
@import "all/custom/variables.scss";
// Import Bootstrap source files (Required file)
@import "all/bootstrap/bootstrap.scss";
@import "all/custom/custom.scss";
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/simple-line-icons/css/simple-line-icons.css";
@import "all/icons/weather-icons/css/weather-icons.css";
@import "all/icons/themify-icons/themify-icons.css";
@import "all/icons/flag-icon-css/flag-icon.min.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "all/icons/crypto-icons/cryptocoins.css";
@import "./media-queries.scss";

@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~@adyen/adyen-web/dist/adyen.css";

@import "global.scss";
@import "@components/Auth/LoginForm.scss";
@import "@components/Auth/SignUpForm.scss";
@import "@components/Auth/ConfirmEmailChange.scss";
@import "@components/Auth/Activation.scss";
@import "@components/Auth/ForgotPasswordForm.scss";
@import "@components/CompleteAccount/PersonalDetails.scss";
@import "@components/RadioButtonGroup/RadioButtonGroup.scss";
@import "@components/ReactTable/ReactTable.scss";
@import "@components/Header/Header.scss";
@import "@components/Navigation/Navigation.scss";
@import "@components/Breadcrumbs/Breadcrumbs.scss";
@import "@components/Pagination/Pagination.scss";
@import "@components/Switch/Switch.scss";
@import "@components/Sidebar/Sidebar.scss";
@import "@components/Home/Home.scss";
@import "@components/Subscriptions/Subscriptions.scss";
@import "@components/GiftVoucher/GiftVoucher.scss";
@import "@components/DiscountVoucher/VoucherFulfillment.scss";
@import "@components/GiftVoucher/GiftVoucherAmount.scss";
@import "@components/LicensePlate/LicensePlate.scss";
@import "@components/Invoice/Invoice.scss";
@import "@components/Transactions/Transactions.scss";
@import "@components/CleaningCard/CleaningCard.scss";
@import "@components/Credit/Credit.scss";
@import "@components/Credit/ChargeCredit.scss";
@import "@components/MyDetails/MyDetails.scss";
@import "@components/LoginDetails/LoginDetails.scss";
@import "@components/Webshop/Webshop.scss";
@import "@components/DatePicker/DatePicker.scss";
@import "@layouts/Auth.scss";
@import "@layouts/Dashboard.scss";
@import "@layouts/CompleteAccount.scss";

.Breadcrumbs {
  background: $white;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1.3rem;
  h3 {
    text-transform: capitalize;
  }
  .breadcrumb {
    background-color: $white;
    margin: 0;
    padding: 0;
    border-radius: 0;
    &-item {
      & + .breadcrumb-item::before {
        content: ">";
        width: 10px;
        height: 20px;
        font-size: 10px;
      }
      a {
        color: $black;
      }
    }
  }
}

.Home {
  flex: 1;
  display: flex;
  flex-direction: column;

  .row {
    flex: 1;
    margin: 0;

    .col-lg-4 {
      display: flex;
      flex-direction: column;
    }

    .col-lg-12 {
      display: flex;
    }

    .card {
      flex: 1;

      &:first-child {
        margin-bottom: 15px;
      }

      &:nth-child(n + 2) {
        margin: 0;
      }

      &:only-child {
        margin: 0;
      }
    }
  }

  .gift-voucher-full-width-wrapper {
    padding: 4rem 2.2rem 1.25rem 3.2rem;

    &::before {
      content: "";
      height: 100%;
      border-left: solid 9px $white;
      position: absolute;
      left: 40px;
      top: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      border-bottom: solid 9px $white;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 1;
    }

    .gift-voucher-full-width {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      & > img {
        position: absolute;
        left: 6px;
        top: 24px;
        z-index: 2;
      }

      & > *:not(img) {
        flex-basis: 50%;
      }

      & > .card-title {
        order: 1;
      }

      & > .card-text {
        order: 2;
        margin-top: 0 !important;
        text-align: right;
      }

      & > .button-gift {
        order: 3;

        & > button {
          width: auto;
        }
      }

      & > .redeem-gift {
        order: 4;
        text-align: right;
      }

      .card {
        &-title,
        &-text {
          color: $white;
        }
      }
    }
  }

  .gift-voucher-wrapper {
    padding: 2.5rem 0 0 2.5rem;
    position: relative;

    &::before {
      content: "";
      height: 100%;
      border-left: solid 9px $white;
      position: absolute;
      left: 40px;
      top: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      border-bottom: solid 9px $white;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 1;
    }

    .gift-voucher {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      position: relative;
      padding: 2rem;

      & > img {
        position: absolute;
        left: -34px;
        top: -18px;
        z-index: 2;
      }

      .card {
        &-title,
        &-text {
          color: $white;
        }
      }

      & > .btn-secondary {
        border-color: var(--white);
        color: var(--white);

        &:hover {
          background: var(--white);
          color: var(--secondaryBgColor);
        }
      }

      & > .link-secondary {
        border-color: var(--white);
        color: var(--white);
      }
    }
  }

  .identifications {
    .rt-tr > .rt-td:first-child {
      white-space: normal;
      overflow: auto;
      text-overflow: clip;
      flex: auto !important;
      width: auto !important;
    }
  }

  .button-group {
    &.chargeable-amount {
      & > a.btn-primary {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.4rem;
      }
    }
  }
}

@media #{$media-tablet} {
  .Home {
    .table-responsive {
      display: table;
      table-layout: fixed;

      table {
        overflow-x: scroll;
        display: block;
      }
    }
  }
}

@media #{$media-mobile} {
  .Home {
    .table-responsive {
      display: table;
      table-layout: fixed;

      table {
        overflow-x: scroll;
        display: block;
      }
    }

    .identifications {
      .table-responsive > .table {
        & > tbody {
          display: table;
          width: 100%;

          & tr > td {
            padding-left: 10%;
          }
        }
      }
    }

    .row {
      .gift-voucher {
        padding: 2.25rem;
      }

      .gift-voucher-full-width-wrapper {
        padding-right: 0.25rem;
        .gift-voucher-full-width {
          justify-content: flex-start;
          & > *:not(img) {
            flex-basis: auto;
            text-align: left;
          }
        }
      }
    }
  }
}

.react-table {
  tbody {
    .license-plate-row {
      display: flex;
    }
  }
}

.pick-month-element {
  display: flex;
  flex-flow: row;
  gap: 4px;
  width: 100%;

  .pick-month,
  .pick-year {
    flex: auto;

    .form-control.pick-month-input,
    .form-control.pick-year-input {
      padding: 0;
      font-size: 0.875rem;
    }
  }
}

.rdp-calendar {
  .text-primary {
    color: var(--linkSecondaryColor);
  }

  .bg-primary {
    background: var(--primaryBgColor);
    color: var(--primaryTextColor);
  }
}

:root {
  --primaryBgColor: #003d73;
  --primaryTextColor: #ffffff;
  --activePrimaryBgColor: #003d73;
  --activePrimaryTextColor: #ffffff;
  --iconBtnPrimaryBgColor: #003d73;
  --iconBtnPrimaryBorderColor: #003d73;
  --iconBtnPrimaryTextColor: #003d73;
  --btnPrimaryBgColor: #ffffff;
  --btnPrimaryBorderColor: #029ee1;
  --btnPrimaryColor: #029ee1;
  --btnSecondaryBgColor: #003d73;
  --btnSecondaryBorderColor: #003d73;
  --btnSecondaryColor: #ffffff;
  --linkPrimaryColor: #003d73;
  --linkSecondaryColor: #029ee1;
  --linkActivePrimaryColor: #029ee1;
  --breadCrumbsPrimaryColor: #029ee1;
  --secondaryBgColor: #029ee1;
  --secondaryTextColor: #ffffff;
  --badgeLicensePlateBgColor: #ffffff;
  --badgeLicensePlateTextColor: #ba1d1d;
  --badgeLicensePlateBorderColor: #ba1d1d;
  --badgeLicenseRefusedBgColor: #fef3f2;
  --promoBgColor: #fa6d15;
  --promoTextColor: #ffffff;
  --pageBgColor: #eef5f9;

  .btn-primary {
    background: var(--btnPrimaryBgColor);
    border: 2px solid var(--btnPrimaryBorderColor);
    color: var(--btnPrimaryColor);
  }

  .btn-secondary {
    background: var(--btnSecondaryBgColor);
    border: 2px solid var(--btnSecondaryBorderColor);
    color: var(--btnSecondaryColor);
  }

  a {
    color: var(--linkPrimaryColor);
    border-bottom: 1px solid var(--linkPrimaryColor);

    &.active {
      color: var(--linkActivePrimaryColor);
    }
  }

  .link-secondary {
    color: var(--linkSecondaryColor);
  }

  nav {
    background: var(--primaryBgColor) !important;
    color: var(--primaryTextColor);
  }

  .bg-primary {
    background: var(--primaryBgColor);
  }

  .breadcrumbs__title {
    color: var(--breadCrumbsPrimaryColor);
  }

  .text-primary {
    color: var(--textPrimary);
  }
}

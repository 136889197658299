.onoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch.not-active {
  opacity: 0.5;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #6666661d;
  border-radius: 15px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  font-size: 16px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  text-transform: uppercase;
  content: attr(data-on-label);
  padding-left: 11px;
  background-color: var(--btnPrimaryBgColor);
  color: var(--btnPrimaryColor);
}
.onoffswitch-inner:after {
  text-transform: uppercase;
  content: attr(data-off-label);
  padding-right: 11px;
  background-color: var(--btnPrimaryBgColor);
  color: var(--btnPrimaryColor);
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 20px;
  margin: 3px;
  background: var(--btnSecondaryBgColor);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  border: 1px solid var(--btnSecondaryBorderColor);
  border-radius: 15px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.ForgotPassword {
  //display: flex;
  justify-content: center;
  align-items: center;

  .language-switcher {
    width: auto;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  .row {
    margin: 0;
  }
  .card {
    width: 1000px;
    display: flex;
    flex-flow: column wrap;
    border-radius: 25px;
    padding: 1.6rem 2.4rem;
    &-body {
      padding: 0;
    }
    .p-3 {
      padding-left: 0 !important;
    }
    .img-wrapper {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    form {
      .btn-primary {
        background-color: var(--btnPrimaryBgColor);
        color: var(--btnPrimaryColor);
        width: 100%;
        border-radius: 8px;
        padding: 0.65rem 0.75rem;
        &:hover {
          transform: none;
        }
      }
      .form-control {
        padding: 0.375rem 0;
      }
      .custom-control {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
    .sign-up {
      display: block;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}

@media #{$media-mobile} {
  .ForgotPassword {
    .card {
      width: auto;
      display: flex;
      flex-flow: column wrap;
      border-radius: 25px;
      padding: 1.6rem 2.4rem;
    }
  }
}

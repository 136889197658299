.Page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.35),
      rgba(255, 255, 255, 0.35)
    ),
    url("../assets/images/background/default-bg-pwg.jpg") no-repeat center;
  background-size: cover;
}

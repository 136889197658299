.Subscriptions {
  .row {
    margin: 0;

    .col-md-12 {
      display: flex;
      flex-direction: column;
    }

    .card {
      flex: 1;

      .react-table {
        tr td {
          @media #{$media-mobile} {
            padding: 12px 6px 12px 0;
          }

          &:last-child {
            @media #{$media-mobile} {
              padding-right: 0;
            }
          }
        }

        .subscription-label {
          @media #{$media-mobile} {
            margin-right: 0;
          }

          @media only screen and (min-width: 481px) {
            margin-right: 20px;
          }

          & + div {
            @media #{$media-mobile} {
              margin-top: 4px;
            }
          }
        }

        .subscription-type {
          @media #{$media-mobile} {
            margin-top: 4px;
          }

          &:first-child {
            @media #{$media-mobile} {
              margin-left: 0;
            }
          }
        }

        .subscription-date-to {
          @media #{$media-mobile} {
            margin-top: 4px;
          }

          &:first-child {
            @media #{$media-mobile} {
              margin-left: 0;
            }
          }
        }
      }

      &:first-child {
        margin-bottom: 15px;
      }

      .sorts {
        display: flex;
        justify-content: space-around;

        & > .card-title {
          flex: 0.1;
        }

        .button-group {
          flex: 0.95;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;

          button {
            min-width: 30%;
            flex: 0.3;

            .fa-info::before {
              position: absolute;
              top: -10px;
              right: 35px;
              border-radius: 50%;
              background: $blue;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $white;
            }
          }
        }
      }

      &:nth-child(n + 2) {
        margin: 0;
      }

      &:only-child {
        margin: 0;
      }
    }
  }

  .invoice-data {
    display: flex;
    flex-flow: column wrap;

    .card-title {
      flex: 0.1;
    }

    .name-wrapper,
    .company-wrapper {
      flex: 0.2;
    }

    .link-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    .no-invoice-data {
      margin-top: 30px;

      & + .link-wrapper {
        margin-bottom: auto;
      }
    }

    .change-vat {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-top: 1rem;

      .fa-info::before {
        border-radius: 50%;
        background: var(--primaryBgColor);
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }

      .link-wrapper {
        flex: 0.3;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }

      &-description {
        flex: 0.95;
        display: flex;
        flex-flow: column wrap;

        p:nth-child(1) {
          color: var(--primaryBgColor);
          font-weight: 600;
        }
      }
    }
  }

  .gift-voucher-full-width-wrapper {
    padding: 4rem 2.2rem 1.25rem 3.2rem;

    &::before {
      content: "";
      height: 100%;
      border-left: solid 9px $white;
      position: absolute;
      left: 40px;
      top: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      border-bottom: solid 9px $white;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 1;
    }

    .gift-voucher-full-width {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      & > img {
        position: absolute;
        left: 6px;
        top: 24px;
        z-index: 2;
      }

      & > *:not(img) {
        flex-basis: 50%;
      }

      & > .card-title {
        order: 1;
      }

      & > .card-text {
        order: 2;
        margin-top: 0 !important;
        text-align: right;
      }

      & > .button-gift {
        order: 3;

        & > button {
          width: auto;
        }
      }

      & > .redeem-gift {
        order: 4;
        text-align: right;
      }

      .card {
        &-title,
        &-text {
          color: $white;
        }
      }
    }
  }

  .gift-voucher-wrapper {
    padding: 2.5rem 0 0 2.5rem;
    position: relative;

    &::before {
      content: "";
      height: 100%;
      border-left: solid 9px $white;
      position: absolute;
      left: 40px;
      top: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      width: 100%;
      border-bottom: solid 9px $white;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 1;
    }

    .gift-voucher {
      display: flex;
      align-items: flex-start;
      flex-flow: column wrap;
      position: relative;
      padding: 2rem;

      & > img {
        position: absolute;
        left: -34px;
        top: -18px;
        z-index: 2;
      }

      .card {
        &-title,
        &-text {
          color: $white;
        }
      }

      & > .btn-secondary {
        border-color: var(--white);
        color: var(--white);

        &:hover {
          background: var(--white);
          color: var(--secondaryBgColor);
        }
      }

      & > .link-secondary {
        border-color: var(--white);
        color: var(--white);
      }
    }
  }

  .personal-data {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    .card-title {
      padding-top: 2.2rem;
      padding-bottom: 1rem;
    }

    .name-wrapper {
      flex: 0.2;
    }

    .link-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }
}

@media #{$media-tablet} {
  .Subscriptions {
    .row {
      .card {
        .sorts {
          flex-direction: column;

          & > .button-group {
            flex-wrap: wrap;

            & > button {
              flex: 1;
              width: auto;
              min-width: 30%;
            }
          }
        }
      }
    }
  }
}

@media #{$media-mobile} {
  .Subscriptions {
    .row {
      .card {
        .sorts {
          flex-direction: column;

          & > .button-group {
            flex-direction: column;

            & > button {
              width: 100%;
            }
          }
        }
      }

      .gift-voucher {
        padding: 2.25rem;
      }

      .gift-voucher-full-width-wrapper {
        padding-right: 0.25rem;

        .gift-voucher-full-width {
          justify-content: flex-start;

          & > *:not(img) {
            flex-basis: auto;
            text-align: left;
          }
        }
      }
    }
  }
}

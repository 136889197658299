.LoginDetails {
  .col-md-12 {
    display: flex;
    flex-direction: column;
    .card {
      flex: 1;
    }
  }
  .form-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    .control-label {
      width: 30%;
    }
    & > .input-wrapper {
      flex: 0.9;
    }
  }
}

@media #{$media-mobile} {
  .LoginDetails {
    .form-group {
      flex-flow: column wrap;
      align-items: stretch;
      & > .control-label {
        width: auto;
      }
    }
  }
}

.left-sidebar {
  position: fixed;
  width: 300px;
  min-height: 100vh;
  height: auto;
  padding: 1.6rem;

  @media #{$media-tablet} {
    width: 350px;
  }

  @media #{$media-desktop} {
    width: 400px;
  }

  .left-sidebar-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ti-close {
    text-align: right;
    margin-bottom: 5px;
  }

  .logo {
    margin: auto;
    border-bottom: 0;

    img {
      max-height: 100px;
      width: 100%;
    }
  }

  .sidebar-nav {
    ul {
      padding: 45px 15px;
    }
  }

  .first-level {
    width: 100%;
    padding-bottom: 26px;
    font-size: 15px;

    &:first-child {
      & > .sidebar-link {
        display: flex;
        align-items: center;
        width: 100%;

        padding: 0 10%;
        line-height: 1.5;
        border-radius: 4px;
        border-bottom: 0;

        &:hover {
          color: var(--btnPrimaryColor);
        }

        &.active {
          color: var(--btnPrimaryColor);
          background-color: var(--btnPrimaryBgColor);
        }
      }
    }

    &:not(:nth-child(1)) {
      padding-left: 10%;
    }

    &:last-child {
      padding-bottom: 0;
    }

    & > .sidebar-link {
      color: var(--linkPrimaryColor);
      text-transform: capitalize;
      display: flex;
      align-items: center;
      border-bottom: 0;
      transition: color 0.3s ease-in;

      &:hover {
        color: var(--linkActivePrimaryColor);
      }

      &.active {
        color: var(--linkActivePrimaryColor);
        font-weight: 600;
      }

      svg {
        font-size: 20px;
      }

      span {
        padding-left: 12px;
      }
    }
  }

  .nav-additional {
    display: flex;
    align-items: center;
    padding: 10px 35px 10px 25px;
    border-bottom: 2px solid var(--linkActivePrimaryColor);
    color: var(--linkActivePrimaryColor);

    &:first-of-type {
      border-top: 2px solid var(--linkActivePrimaryColor);
    }

    svg {
      font-size: 20px;
    }

    a,
    span {
      font-weight: 600;
      color: var(--linkActivePrimaryColor);
      border-bottom: 0;
      padding-left: 10px;
    }

    span {
      cursor: pointer;
    }
  }
}

@media #{$media-mobile} {
  .left-sidebar {
    padding: 0.8rem;
    width: 85%;
    height: 100%;

    &.hidden {
      display: none;
    }
  }
}

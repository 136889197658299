.Gift-voucher-amount {
  div {
    div:nth-child(1) {
      .form-group:nth-child(1) {
        position: relative;
        .counter {
          border-radius: 50%;
          background: var(--btnPrimaryBgColor);
          border: 1px solid var(--btnPrimaryBorderColor);
          width: 30px;
          height: 30px;
          color: var(--btnPrimaryColor);
          position: absolute;
          right: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:focus,
          &:active {
            outline: none;
          }
          &.increment {
            top: 10px;
          }
          &.decrement {
            bottom: 10px;
          }
        }
        .enter-amount {
          padding: 4rem 0;
          text-align: center;
          color: var(--primaryBgColor);
          font-weight: 700;
          font-size: 36px;
          border-radius: 4px;
          margin-bottom: 10px;
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }
      }
      .form-check:nth-child(1) {
        align-items: stretch;
        flex-flow: column wrap;
      }
    }
    div:nth-child(2) {
      .form-group:nth-child(1) {
        position: relative;
        .counter {
          border-radius: 50%;
          background-color: var(--btnPrimaryBgColor);
          border: 1px solid var(--btnPrimaryBorderColor);
          width: 30px;
          height: 30px;
          color: var(--btnPrimaryColor);
          position: absolute;
          right: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:focus,
          &:active {
            outline: none;
          }
          &.increment {
            top: 10px;
          }
          &.decrement {
            bottom: 10px;
          }
        }
        .enter-number-of-items {
          padding: 4rem 0;
          text-align: center;
          color: var(--primaryBgColor);
          font-weight: 700;
          font-size: 36px;
          border-radius: 4px;
          margin-bottom: 10px;
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }
      }
      .form-check:nth-child(1) {
        align-items: stretch;
        flex-flow: column wrap;
      }
    }
  }
  .conditions {
    display: flex;
  }
}

.GiftVoucher {
  .gift-variable-top {
    top: -13px;
  }
  .row {
    margin: 0;
    .col-md-12 {
      display: flex;
      flex-direction: column;
    }
    .card {
      flex: 1;

      .personal-data {
        .card-title {
          padding-top: 2.2rem;
          padding-bottom: 1rem;
        }
      }

      &:first-child {
        margin-bottom: 15px;
      }
      .sorts {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .button-group {
          flex: 1;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-around;
          align-items: center;
          button {
            display: flex;
            width: 30%;
          }
        }
      }
      .bg-secondary {
        .card-title,
        .card-subtitle {
          color: $white;
        }
        .card-title:first-child {
          min-height: 100px;
        }
        input {
          border-radius: 12px;
          width: 125px;
        }
        form {
          margin-bottom: 2rem;
        }
      }
      &:nth-child(n + 2) {
        margin: 0;
      }
      &:only-child {
        margin: 0;
      }
    }
  }
  .gift-message-form {
    flex-flow: row wrap;
    flex: 1;
    .counter-input {
      position: relative;
    }
    .Charge-credit {
      width: 50%;
    }
    &-item {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
    }
  }
}
.gift-voucher-full-width-wrapper {
  padding: 4rem 2.2rem 1.25rem 3.2rem;

  &::before {
    content: "";
    height: 100%;
    border-left: solid 9px $white;
    position: absolute;
    left: 40px;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 9px $white;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 1;
  }

  .gift-voucher-full-width {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    & > img {
      position: absolute;
      left: 6px;
      top: 24px;
      z-index: 2;
    }

    & > *:not(img) {
      flex-basis: 50%;
    }

    & > .card-title {
      order: 1;
    }

    & > .card-text {
      order: 2;
      margin-top: 0 !important;
      text-align: right;
    }

    & > .button-gift {
      order: 3;

      & > button {
        width: auto;
      }
    }

    & > .redeem-gift {
      order: 4;
      text-align: right;
    }

    .card {
      &-title,
      &-text {
        color: $white;
      }
    }
  }
}

.gift-voucher-wrapper {
  padding: 2.5rem 0 0 2.5rem;
  position: relative;
  color: var(--secondaryTextColor);
  background-color: var(--secondaryBgColor) !important;

  &::before {
    content: "";
    height: 100%;
    border-left: solid 9px $white;
    position: absolute;
    left: 40px;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 9px $white;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 1;
  }

  .gift-voucher {
    display: flex;
    flex-flow: column wrap;
    position: relative;
    padding: 3rem;

    & > img {
      position: absolute;
      left: -34px;
      top: -18px;
      z-index: 2;
    }

    form {
      .form-group {
        & > input {
          border-radius: 10px;
        }
      }
    }

    .card {
      &-title,
      &-text {
        color: $white;
      }
    }
  }
}

@media #{$media-mobile} {
  .GiftVoucher {
    .row {
      .card {
        .sorts {
          flex-direction: column;
          & > .button-group {
            flex-direction: column;
            & > button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media #{$media-tablet} {
  .GiftVoucher {
    .row {
      .card {
        .sorts {
          flex-direction: column;
          .button-group {
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.radio-button-group-wrapper {
  margin-bottom: 1.563rem;

  @media #{$media-tablet} {
    display: flex;
  }

  @media #{$media-desktop} {
    display: flex;
  }

  .radio-button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid #e9ecef;
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 0.75rem;

      @media #{$media-tablet} {
        margin-right: 0.75rem;
        margin-bottom: 0;
      }

      @media #{$media-desktop} {
        margin-right: 0.75rem;
        margin-bottom: 0;
      }
    }

    &.checked {
      background: #f8f9fc;
    }

    label {
      width: 100%;
      margin-bottom: 0;
      padding-right: 1.5rem;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.page-wrapper {
  background: var(--pageBgColor);
  display: flex;
  flex-flow: row wrap;
  .content {
    width: 100%;
    min-height: 100vh;

    main {
      flex-grow: 1;
      @media #{$media-mobile} {
        display: flex;
      }

      & > div {
        .row {
          margin: 0;
        }
      }
    }
  }

  @media #{$media-tablet} {
    .content {
      width: calc(100% - 350px);
      margin-left: 350px;
    }
  }

  @media #{$media-desktop} {
    .content {
      width: calc(100% - 400px);
      margin-left: 400px;
    }
  }
}

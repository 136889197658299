.VoucherFulfillment {
  //display: flex;
  justify-content: center;
  align-items: center;

  .language-switcher {
    width: auto;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  .row {
    margin: 0;
    overflow: hidden;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .card {
    display: flex;
    border-radius: 25px;
    padding: 1.6rem 2.4rem;
    &-body {
      padding: 0;
    }
    .p-3 {
      padding-left: 0 !important;
    }
    .img-wrapper {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .bulletPoint {
      width: 20px;
    }
    .image {
      height: 100%;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      max-height: 400px;
      width: auto;
      max-width: 100%;
    }
    .logoImage {
      height: 50%;
      width: 90%;
      margin: 25% 5%;
      border-radius: 0;
    }
    .leftColumn {
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      padding: 0;
    }
    .rightColumn {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
      background-color: #ffffff;
    }
    .rightColumn.no-image {
      border-radius: 25px;
    }
    form {
      .btn-primary {
        background-color: var(--btnPrimaryBgColor);
        color: var(--btnPrimaryColor);
        width: 100%;
        border-radius: 8px;
        padding: 0.65rem 0.75rem;
        &:hover {
          transform: none;
        }
      }
      .form-control {
        padding: 0.375rem 0;
      }
      .custom-control {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
    .sign-up {
      display: block;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}

@media #{$media-mobile} {
  .VoucherFulfillment {
    .img-wrapper {
      display: block;
      text-align: center;
      padding-top: 1rem;
    }
    .card {
      .leftColumn {
        border-radius: 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      .rightColumn {
        border-radius: 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      .image {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        max-height: none;
      }
    }
  }
}

@media #{$media-desktop} {
  .VoucherFulfillment {
    .card {
      width: 1000px;
    }
  }
}

.Signup {
  //display: flex;
  justify-content: center;
  align-items: center;

  .row {
    margin: 0;
  }

  .card {
    display: flex;
    flex-flow: column wrap;
    border-radius: 25px;
    padding: 1.6rem 2.4rem;

    .language-switcher {
      width: auto;
      margin-bottom: 1rem;
      margin-left: auto;
    }

    &-body {
      padding: 0;
    }

    .p-3 {
      padding-left: 0 !important;
    }

    .img-wrapper {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    form {
      .btn-primary {
        background-color: var(--btnPrimaryBgColor);
        color: var(--btnPrimaryColor);
        border-radius: 8px;
        padding: 0.65rem 0.75rem;

        &:hover {
          transform: none;
        }
      }

      .form-control {
        padding: 0.375rem 0.75rem;
      }

      .custom-control {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }

    .sign-up {
      display: block;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }

  .btn-register {
    width: auto;
  }
}

@media #{$media-mobile} {
  .Signup {
    padding: 15px 0;

    .card {
      padding: 0.8rem 1.2rem;

      .register-links-wrapper {
        margin-top: 0;
        margin-bottom: 8px;

        & > div:nth-child(2) {
          margin-top: 8px;
          text-align: center !important;
        }
      }
    }

    form {
      .row > .col-md {
        &-2,
        &-3,
        &-4,
        &-5,
        &-12 {
          padding: 0;
        }

        &-5 > .row > div {
          margin-top: 8px;
        }
      }
    }

    .btn-register-wrapper {
      text-align: center;
      padding: 0;
    }

    .btn-register {
      width: 100%;
    }
  }
}

@media #{$media-desktop} {
  .Signup {
    .card {
      width: 1000px;
    }
  }
}

//Buttons
.btn {
  position: relative;
  transition: 0.5s all ease;

  &.btn-primary {
    &:hover {
      background: var(--primaryBgColor);
      border-color: var(--primaryBgColor);
      color: var(--primaryTextColor);
    }

    &.disabled,
    &:disabled {
      background-color: var(--white);
      border-color: var(--primaryBgColor);
      color: var(--primaryBgColor);
    }

    &:not(:disabled):not(.disabled) {
      &:active {
        background: var(--primaryBgColor);
        border-color: var(--primaryBgColor);
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  &.btn-secondary {
    &:hover {
      background: var(--secondaryBgColor);
      border-color: var(--secondaryBgColor);
      color: var(--white);
    }

    &.disabled,
    &:disabled {
      background-color: var(--white);
      border-color: var(--secondaryBgColor);
      color: var(--secondaryBgColor);
    }

    &:not(:disabled):not(.disabled) {
      &:active {
        background: var(--secondaryBgColor);
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-rounded {
  border-radius: 60px;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 12px;
  }

  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}

// Brand buttons

@each $color, $value in $brand-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}

.AlmostDone {
  flex: 1;

  .language-switcher {
    width: auto;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  & > .row {
    margin: 0;
  }

  .img-wrapper {
    margin-bottom: 1.563rem;
  }

  .card {
    padding: 0.938rem;
    margin: 0.938rem 0;

    @media #{$media-tablet} {
      padding: 1.875rem;
    }

    @media #{$media-desktop} {
      width: 1000px;
      margin: 0.938rem auto;
      padding: 3.125rem;
    }

    .card-title {
      margin-bottom: 1.563rem;
    }

    .card-body {
      padding: 0;
    }
  }

  .vat-number-wrapper {
    margin-bottom: 0.5rem;
  }

  .vat-number {
    input {
      margin-bottom: 0.5rem;

      @media #{$media-tablet} {
        margin-bottom: 0;
      }

      @media #{$media-desktop} {
        margin-bottom: 0;
      }
    }
  }

  .vat-number-requested-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    label {
      margin-bottom: 0;
      margin-left: 0.75rem;
    }
  }
}
